import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import HomeView from "../views/HomeView";
import AuthPage from "../components/pages/AuthPage";

import LogoutView from "../views/LogoutView";
import CreateWorkspaceView from "../views/CreateWorkspaceView";
import * as Sentry from "@sentry/react";
import ErrorFallback from "../components/ErrorFallback";
import LoginView from "../views/LoginView";
import NotFound from "../components/NotFound";
import WorkspaceSettingsView from "../views/WorkspaceSettingsView";
import AcceptInviteView from "../views/AcceptInviteView";
import LoggedOutPage from "../components/pages/LoggedOutPage";
import WorkspaceListView from "../views/WorkspaceListView";
import SettingsView from "../views/SettingsView";
import ProjectListView from "../views/ProjectListView";
import ProjectCreateView from "../views/ProjectCreateView";
import ProjectView from "../views/ProjectView";
import TraceView from "../views/TraceView";
import ProjectSettingsView from "../views/ProjectSettingsView";
import CaseView from "../views/CaseView";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
        <App />
      </Sentry.ErrorBoundary>
    ),
    errorElement: <NotFound />,
    children: [
      {
        path: "login",
        element: (
          <LoggedOutPage title="Login">
            <LoginView />
          </LoggedOutPage>
        ),
      },
      {
        path: "logout",
        element: <LogoutView />,
      },
      {
        path: "",
        element: (
          <AuthPage>
            <HomeView />
          </AuthPage>
        ),
      },
      {
        path: "workspaces",
        element: (
          <AuthPage
            title="Workspaces"
            showNav={false}
            requireActiveWorkspace={false}
          >
            <WorkspaceListView />
          </AuthPage>
        ),
      },
      {
        path: "workspaces/create",
        element: (
          <AuthPage
            requireActiveWorkspace={false}
            showNav={false}
            title="Create New Workspace"
          >
            <CreateWorkspaceView />
          </AuthPage>
        ),
      },
      {
        path: "workspace/settings",
        element: (
          <AuthPage title="Settings" requireActiveWorkspace={false}>
            <WorkspaceSettingsView />
          </AuthPage>
        ),
      },
      {
        path: "projects",
        element: (
          <AuthPage title="Projects">
            <ProjectListView />
          </AuthPage>
        ),
      },
      {
        path: "projects/create",
        element: (
          <AuthPage title="Create Project">
            <ProjectCreateView />
          </AuthPage>
        ),
      },
      {
        path: "projects/:projectId",
        element: (
          <AuthPage title="Project" constrainToViewportHeight={true}>
            <ProjectView />
          </AuthPage>
        ),
      },
      {
        path: "projects/:projectId/settings",
        element: (
          <AuthPage title="Project Settings">
            <ProjectSettingsView />
          </AuthPage>
        ),
      },
      {
        path: "traces/:traceId",
        element: (
          <AuthPage title="Project">
            <TraceView />
          </AuthPage>
        ),
      },
      {
        path: "cases/:caseId",
        element: (
          <AuthPage title="Project">
            <CaseView />
          </AuthPage>
        ),
      },
      {
        path: "invite/:token/accept",
        element: (
          <LoggedOutPage title="Accept Invitation">
            <AcceptInviteView />
          </LoggedOutPage>
        ),
      },
      {
        path: "settings",
        element: (
          <AuthPage>
            <SettingsView />
          </AuthPage>
        ),
      },
    ],
  },
]);

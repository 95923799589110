import { ReactElement, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectActiveWorkspace } from "../../features/workspaces/workspaceSlice";
import { CenteredSpinner } from "../Spinner";
import { fetchAll as fetchAllInvites } from "../../features/team/inviteAPI";
import SideNav from "../../features/nav/SideNav";
interface AuthPageProps {
  children?: ReactElement | Array<ReactElement>;
  title?: string;
  showNav?: boolean;
  requireActiveWorkspace?: boolean;
  constrainToViewportHeight?: boolean;
}

export default function AuthPage({
  children,
  title,
  showNav,
  requireActiveWorkspace = true,
  constrainToViewportHeight = false,
}: AuthPageProps) {
  const user = useSelector((state: any) => state.auth.user);
  const isLoading = useSelector((state: any) => state.auth.isAuthPending);
  const activeWorkspace = useSelector(selectActiveWorkspace);

  const navigate = useNavigate();
  useEffect(() => {
    if (title) document.title = title + " - Plucky";
    else document.title = "Plucky";
  }, [title]);
  useEffect(() => {
    handleRedirect();
  }, [user, isLoading, activeWorkspace?.id]);

  async function handleRedirect() {
    if (isLoading) return;
    if (!user) {
      navigate("/login");
      return;
    }
    if (activeWorkspace) return;
    if (!requireActiveWorkspace) return;
    const { results: invites } = await fetchAllInvites({
      received: true,
    });
    if (invites.length > 0) {
      navigate("/workspaces");
    } else {
      navigate("/workspaces/create");
    }
  }
  if (!user || (!activeWorkspace && requireActiveWorkspace))
    return <CenteredSpinner />;
  return (
    <div
      className="flex-1 flex flex-row"
      style={{ maxHeight: constrainToViewportHeight ? "100vh" : undefined }}
    >
      {showNav !== false && <SideNav user={user} />}
      <Suspense fallback={<CenteredSpinner />}>
        <div className="flex-1 flex flex-col overflow-auto">{children}</div>
      </Suspense>
    </div>
  );
}

import { ReactElement, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectActiveWorkspaceId } from "../../features/workspaces/workspaceSlice";
import Spinner, { CenteredSpinner } from "../Spinner";

interface LoggedOutPage {
  children?: ReactElement | Array<ReactElement>;
  title?: string;
  showNav?: boolean;
  requireActiveWorkspace?: boolean;
}

export default function LoggedOutPage({ children, title }: LoggedOutPage) {
  const user = useSelector((state: any) => state.auth.user);
  const isLoading = useSelector((state: any) => state.auth.isAuthPending);
  const navigate = useNavigate();
  useEffect(() => {
    if (title) document.title = title + " - Plucky";
    else document.title = "Plucky";
  }, [title]);
  useEffect(() => {
    if (isLoading) return;
    if (user) {
      navigate("/");
      return;
    }
  }, [user, isLoading]);
  if (isLoading) return <CenteredSpinner />;
  return (
    <div className="flex-1 flex flex-row">
      <Suspense fallback={<CenteredSpinner />}>
        <div className="flex-1 flex flex-col">{children}</div>
      </Suspense>
    </div>
  );
}

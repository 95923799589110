import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TeamList from "../features/team/TeamList";
import { selectAllMembersOfActiveWorkspace } from "../features/team/memberSlice";
import {
  getWorkspaceMembers,
  selectActiveWorkspace,
  selectActiveWorkspaceId,
} from "../features/workspaces/workspaceSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { InviteModal } from "../features/team/InviteModal";
import {
  getAllWorkspaceInvites,
  selectAllInvitesOfActiveWorkspace,
} from "../features/team/inviteSlice";
import WorkspaceSettingsForm from "../features/workspaces/WorkspaceSettingsForm";
import PaddedContainer from "../components/containers/PaddedContainer";
import { CenteredSpinner } from "../components/Spinner";
import { throwIfError } from "../utils/error";
import { handleError } from "../features/errors/errorUtils";
import {
  getAllLabels,
  selectAllLabelsInWorkspace,
} from "../features/tracing/labels/labelSlice";
import LabelList from "../features/tracing/labels/LabelList";

export default function WorkspaceSettingsView() {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const members = useAppSelector(selectAllMembersOfActiveWorkspace);
  const invites = useAppSelector(selectAllInvitesOfActiveWorkspace);
  const labels = useAppSelector(selectAllLabelsInWorkspace);

  const workspaceId = useAppSelector(selectActiveWorkspaceId);
  if (!workspaceId) return null;
  const workspace = useAppSelector(selectActiveWorkspace);
  const dispatch = useAppDispatch();
  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await Promise.all([
        throwIfError(dispatch(getAllLabels())),
        throwIfError(dispatch(getWorkspaceMembers({}))),
        throwIfError(dispatch(getAllWorkspaceInvites({}))),
      ]);
    } catch (err) {
      handleError(err);
    }
  }

  if (!workspace) return <CenteredSpinner />;

  return (
    <div className="pt-6">
      <PaddedContainer>
        <WorkspaceSettingsForm workspace={workspace} />
        <div className="mt-16">
          <TeamList
            members={members}
            invites={invites}
            openInviteModal={() => setShowInviteModal(true)}
          />
        </div>
        <InviteModal
          workspaceId={workspaceId}
          show={showInviteModal}
          setShow={setShowInviteModal}
        />
        <div className="mt-24">
          <LabelList labels={labels} />
        </div>
      </PaddedContainer>
    </div>
  );
}

import axios from "axios";

export async function fetchAll(params: {
  projectId: string;
  skip: number;
  limit: number;
}): Promise<{ results: Array<any>; totalCount: number }> {
  const { data } = await axios.get("/api/cases", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/cases/${id}`);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/cases/${id}`, body);
  return data;
}

import PaddedContainer from "../components/containers/PaddedContainer";
import {
  getAllProjects,
  selectAllProjectsInWorkspace,
} from "../features/projects/projectSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { handleError } from "../features/errors/errorUtils";
import { throwIfError } from "../utils/error";
import ProjectList from "../features/projects/ProjectList";

export default function ProjectListView() {
  const projects = useAppSelector(selectAllProjectsInWorkspace);
  const dispatch = useAppDispatch();
  useEffect(() => {
    loadDatasets();
  }, []);
  async function loadDatasets() {
    try {
      await throwIfError(dispatch(getAllProjects({})));
    } catch (err) {
      handleError(err);
    }
  }
  return (
    <div className="py-24 px-24">
      <ProjectList projects={projects} />
    </div>
  );
}

import { FormEvent, Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { handleError } from "../errors/errorUtils";
import { useAppDispatch } from "../../app/hooks";
import { sendInvite } from "../team/inviteSlice";
import SubmitButton from "../../components/form/SubmitButton";
import { throwOnRejected } from "../../utils/thunk";
interface InviteModalProps {
  show: boolean;
  workspaceId: string;
  setShow: (value: boolean) => void;
}

export function InviteModal({ workspaceId, show, setShow }: InviteModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  async function handleSubmit(e: FormEvent) {
    try {
      setSubmitting(true);
      e.stopPropagation();
      e.preventDefault();
      await throwOnRejected(dispatch(sendInvite({ workspaceId, email })));
      setEmail("");
      setShow(false);
    } catch (err: any) {
      handleError(err.payload);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setShow}
        initialFocus={inputRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => setShow(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Invite Team Members
                </Dialog.Title>
                <form onSubmit={handleSubmit}>
                  <div className="mt-4">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      ref={inputRef}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter team member's email..."
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={submitting}
                    />

                    <div className="mt-4 sm:mt-4 flex flex-row justify-end sm:grid-cols-2 sm:gap-3">
                      <SubmitButton
                        onClick={handleSubmit}
                        submitting={submitting}
                      >
                        Send Invite
                      </SubmitButton>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function GenerateDraftPrompt({ setShow, onStartEmpty }: any) {
  return (
    <div className="relative -top-12">
      <div className="mb-2 text-gray-500">
        Would you like to generate a starting draft?
      </div>
      <div className="flex flex-row">
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 sm:col-start-2"
          onClick={() => setShow(true)}
        >
          Generate Draft
        </button>
        <div
          className="text-gray-500 underline cursor-pointer text-sm flex flex-col justify-center ml-6"
          onClick={onStartEmpty}
        >
          Start Empty
        </div>
      </div>
    </div>
  );
}

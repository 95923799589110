import { format, parseISO } from "date-fns";

export default function Trace({ trace }: { trace: any }) {
  return (
    <div>
      <div>
        <div>{format(parseISO(trace.startedAt), "MMM d, yyyy HH:mmaa")}</div>
        <div>32 seconds</div>
      </div>
      <div className="flex flex-row space-x-8 mt-4">
        <div className="border rounded-md py-4 px-4">
          <div className="font-bold">Inputs</div>
          <div>
            <TraceInput inputs={trace.inputs} />
          </div>
        </div>
        <div className="border rounded-md py-4 px-4">
          <div className="font-bold">Outputs</div>
          <div>{JSON.stringify(trace.outputs)}</div>
        </div>
      </div>
      <div className="whitespace-pre-line">{trace.log}</div>
    </div>
  );
}

interface TraceInputProps {
  data: any;
}

function TraceInput({ inputs }: { inputs: TraceInputProps }) {
  return (
    <div>
      <TraceData data={inputs.data} />
    </div>
  );
}

function TraceData({ data }: { data: any }) {
  const keys = Object.keys(data);
  return (
    <table>
      <tbody>
        {keys.map((key) => (
          <tr key={key}>
            <td>{key}</td>
            <td>
              {typeof data[key] === "string"
                ? data[key]
                : JSON.stringify(data[key])}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

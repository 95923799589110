import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  removeNormalizedItem,
  toArray,
} from "../../utils/normalizedState";
import { getWorkspaceMembers } from "../workspaces/workspaceSlice";

const initialState: NormalizedState<any> = initNormalizedState();

export const workspaceMemberSlice = createSlice({
  name: "members",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addManyItems: (state, action: PayloadAction<Array<any>>) => {
      addNormalizedItems(state, action.payload);
    },
    removeItem: (state, action: PayloadAction<string>) => {
      removeNormalizedItem(state, action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(
      getWorkspaceMembers.fulfilled,
      (state: any, action: PayloadAction<Array<any>>) => {
        addNormalizedItems(state, action.payload);
      }
    );
  },
});

export const selectAllMembersOfActiveWorkspace = createSelector(
  [
    (state: RootState) => state.workspaceMembers,
    (state: RootState) => state.workspaces.activeId,
  ],
  (normalizedWorkspaceMembers: any, activeWorkspaceId: string | null) => {
    if (!activeWorkspaceId) return [];
    return toArray(normalizedWorkspaceMembers).filter(
      (member: any) => member.workspaceId === activeWorkspaceId
    );
  }
);

export default workspaceMemberSlice.reducer;

export function calculateThreadPositions(
  desiredPositions: number[],
  heights: number[],
  padding: number = 0
) {
  const positions: number[] = [];
  let currentPosition = 0;
  for (let i = 0; i < desiredPositions.length; i++) {
    const desiredPosition = desiredPositions[i];

    if (currentPosition < desiredPosition) {
      currentPosition = desiredPosition;
    }
    const height = heights[i];
    positions.push(currentPosition);
    currentPosition += height + padding;
  }
  return positions;
}

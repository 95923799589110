import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import CaseList from "../tracing/cases/CaseList";

export default function Project({ project }: { project: any }) {
  return (
    <>
      <div className="px-8 border-b h-10 flex flex-row items-center">
        <h2 className="text-sm font-medium flex-1">{project.name}</h2>
        <Link
          to={`/projects/${project.id}/settings`}
          className="px-2 py-2 rounded text-gray-500 hover:text-gray-800"
        >
          <Cog6ToothIcon className="w-4 h-4" />
        </Link>
      </div>
      <CaseList projectId={project.id} />
    </>
  );
}

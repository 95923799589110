import { RootState } from "../app/store";
import { CenteredSpinner } from "../components/Spinner";
import PaddedContainer from "../components/containers/PaddedContainer";
import ProfileSettings from "../features/users/ProfileSettings";
import { useSelector } from "react-redux";

export default function SettingsView() {
  const user = useSelector((state: RootState) => state.auth.user);
  if (!user) return <CenteredSpinner />;
  return (
    <div className="pt-12">
      <PaddedContainer>
        <ProfileSettings user={user} />
      </PaddedContainer>
    </div>
  );
}

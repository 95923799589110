import axios from "axios";

export async function fetchAll(params: {
  workspaceId: string;
}): Promise<{ results: Array<any> }> {
  const { data } = await axios.get("/api/projects", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/projects/${id}`);
  return data;
}

export async function create(body: any): Promise<any> {
  const { data } = await axios.post(`/api/projects`, body);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/projects/${id}`, body);
  return data;
}

export async function archive(id: string): Promise<any> {
  const { data } = await axios.post(`/api/projects/${id}/archive`);
  return data;
}

export async function getAPIKey(id: string): Promise<any> {
  const { data } = await axios.get(`/api/projects/${id}/api-key`);
  return data;
}

export async function rotateAPIKey(id: string): Promise<any> {
  const { data } = await axios.post(`/api/projects/${id}/rotate-api-key`);
  return data;
}

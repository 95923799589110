export default function CaseLabel({ label }: { label: any }) {
  return (
    <div
      key={label.id}
      className="flex flex-row text-xs px-3 py-1 border border-gray-300 rounded-full items-center"
    >
      <div
        className="rounded-full w-2 h-2 mr-2"
        style={{ backgroundColor: label.color }}
      ></div>
      <div>{label.name}</div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { getAPIKey, rotateAPIKey } from "./projectAPI";
import { handleError } from "../errors/errorUtils";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export default function ProjectSettings({ project }: { project: any }) {
  const [apiKey, setAPIKey] = useState("");
  useEffect(() => {
    if (!project.id) return;
    loadAPIKey();
  }, [project.id]);

  async function loadAPIKey() {
    try {
      const value = await getAPIKey(project.id);
      setAPIKey(value);
    } catch (err) {
      handleError(err);
    }
  }
  async function handleRotateClick() {
    try {
      const value = await rotateAPIKey(project.id);
      setAPIKey(value);
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <>
      <h2 className="text-sm font-medium py-3">{project.name}</h2>
      <div>
        <label className="block text-sm font-medium text-gray-700 pb-1">
          API Key
        </label>
        <div className="flex flex-row space-x-2">
          <input
            disabled
            value={apiKey}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <button
            onClick={handleRotateClick}
            className="hover:bg-gray-100 rounded px-3"
          >
            <ArrowPathIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    </>
  );
}

import axios from "axios";

export async function fetchAll(params: {
  caseId: string;
  skip: number;
  limit: number;
}): Promise<{ results: Array<any>; totalCount: number }> {
  const { data } = await axios.get("/api/events", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/events/${id}`);
  return data;
}

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  removeNormalizedItem,
  toArray,
} from "../../../utils/normalizedState";
import { fetchAll, fetchOne, update } from "./traceAPI";

interface TraceViewData {
  id: string;
  tracesByIndex: {
    [index: number]: any;
  };
  totalCount: number;
}

const initialState: {
  viewsById: {
    [id: string]: TraceViewData;
  };
  byId: any;
  allIds: Array<string>;
} = { ...initNormalizedState(), viewsById: {} };

export const getAllTraces = createAsyncThunk(
  "traces/getAll",
  async (params: any) => {
    const { skip = 0, limit, ...filter } = params;
    const { results, totalCount } = await fetchAll({
      ...params,
    });
    return { results, totalCount, filter, skip };
  }
);

export const getTrace = createAsyncThunk(
  "traces/getOne",
  async (id: string) => {
    const response = await fetchOne(id);
    return response;
  }
);

export const updateTrace = createAsyncThunk(
  "traces/update",
  async ({ id, ...body }: any) => {
    const response = await update(id, body);
    return response;
  }
);

function serializeFilter(filter: { [key: string]: any }) {
  const obj: any = {};
  const keys = Object.keys(filter).sort();
  for (const key of keys) {
    obj[key] = filter[key];
  }
  return JSON.stringify(obj);
}

export const traceSlice = createSlice({
  name: "traces",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addManyItems: (state, action: PayloadAction<Array<any>>) => {
      addNormalizedItems(state, action.payload);
    },
    removeItem: (state, action: PayloadAction<string>) => {
      removeNormalizedItem(state, action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllTraces.fulfilled,
        (
          state: any,
          action: PayloadAction<{
            results: Array<any>;
            totalCount: number;
            filter: any;
            skip: number;
          }>
        ) => {
          addNormalizedItems(state, action.payload.results);
          const { results, totalCount, filter, skip } = action.payload;
          const viewId = serializeFilter(filter);
          state.viewsById[viewId] = state.viewsById[viewId] || {
            id: viewId,
            tracesByIndex: {},
            totalCount,
          };
          state.viewsById[viewId].totalCount = totalCount;
          state.viewsById[viewId].tracesByIndex = {
            ...state.viewsById[viewId].tracesByIndex,
            ...results.reduce((acc: any, item: any, index: number) => {
              acc[index + skip] = item.id;
              return acc;
            }, {}),
          };
        }
      )
      .addCase(getTrace.fulfilled, (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(state, [action.payload]);
      })
      .addCase(
        updateTrace.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      );
  },
});

export const selectAllTracesInProject = createSelector(
  (state: RootState) => state.traces,
  (state: RootState, projectId: string) => projectId,
  (normalized, projectId) =>
    toArray(normalized).filter((item: any) => item.projectId === projectId)
);

export const selectTraceById = (state: RootState, id: string) =>
  state.traces.byId[id];

export const selectTraceViewByFilter = createSelector(
  (state: RootState) => state.traces.viewsById,
  (state: RootState) => state.traces.byId,
  (state: RootState, filter: any) => serializeFilter(filter),
  (viewsById, tracesById, viewId) => {
    if (!viewsById[viewId]) return null;
    return {
      ...viewsById[viewId],
      tracesByIndex: Object.entries(viewsById[viewId].tracesByIndex)
        .map(([index, id]) => ({
          [index]: tracesById[id],
        }))
        .reduce((acc: any, item: any) => {
          return { ...acc, ...item };
        }, {}),
    };
  }
);

export default traceSlice.reducer;

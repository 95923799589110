export default function NotFound() {
  return (
    <div className="text-center mt-48">
      <div className="font-bold mb-2" style={{ fontSize: 24 }}>
        Not Found
      </div>
      <div className="text-gray-600">
        Sorry, this page does not exist. Please check the URL and try again.
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useCallback, useEffect } from "react";
import { parseISO, format } from "date-fns";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { debounce } from "lodash";
import { getAllCases, selectCaseViewByFilter } from "./caseSlice";
import { CaseStatusIcon } from "./CaseStatus";
import CaseLabel from "../labels/CaseLabel";

export default function CaseList({ projectId }: { projectId: string }) {
  const dispatch = useAppDispatch();
  const filter = { projectId };
  const view = useAppSelector((state) => selectCaseViewByFilter(state, filter));
  useEffect(() => {
    dispatch(getAllCases(filter));
  }, [projectId]);

  const debouncedGetAllSessions = useCallback(
    debounce(({ skip, limit }: { skip: number; limit: number }) => {
      dispatch(getAllCases({ ...filter, skip, limit }));
    }, 250),
    [dispatch, filter]
  );

  const Row = ({ index, style }: { index: number; style: any }) => {
    if (!view) return null;
    const caseData = view.itemsByIndex[index];
    if (!caseData) return null;
    return (
      <Link
        key={caseData.id}
        to={`/cases/${caseData.id}`}
        className="border-b flex flex-row hover:bg-gray-50 cursor-default hover:cursor-default"
        style={style}
      >
        <div className="whitespace-pre-wrap pr-2 text-sm text-gray-900 space-x-2 text-right pl-8 flex flex-col justify-center">
          <CaseStatusIcon status={caseData.status} />
        </div>
        <div className="whitespace-pre-wrap pl-2 pr-3 text-xs text-gray-900 sm:pl-4 flex flex-col justify-center flex-1">
          {caseData.name || "Untitled"}
        </div>
        <div className="whitespace-pre-wrap pl-2 pr-4 text-xs text-gray-900 sm:pl-4 flex flex-row items-center space-x-2">
          {(caseData.labels || []).map((label: any) => (
            <CaseLabel key={label.id} label={label} />
          ))}
        </div>
        <div className="whitespace-pre-wrap pr-6 text-xs text-gray-500 space-x-2 text-right flex flex-col justify-center">
          {format(parseISO(caseData.createdAt), "MMM d")}
        </div>
      </Link>
    );
  };
  if (!view) return null;
  return (
    <div className="flex-1">
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height}
            itemCount={view.totalCount}
            itemSize={38}
            width={width}
            onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
              debouncedGetAllSessions({
                skip: Math.max(visibleStartIndex - 50, 0),
                limit: visibleStopIndex - visibleStartIndex + 50,
              });
            }}
            overscanCount={20}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
}

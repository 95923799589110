import { useEffect } from "react";
import AuthForm from "../features/auth/AuthForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function () {
  const user = useSelector((state: any) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/");
  }, [user]);
  return (
    <div className="flex-1 container--padded">
      <AuthForm title="Log in to Plucky" />
    </div>
  );
}

import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  removeNormalizedItem,
  toArray,
} from "../../utils/normalizedState";
import { fetchAll, fetchOne, add, fetchMembers, update } from "./workspaceAPI";
import { loadAllProfileData, logout } from "../auth/authSlice";

interface InitialWorkspaceState extends NormalizedState<any> {
  activeId: string | null;
}

const initialState: InitialWorkspaceState = {
  ...initNormalizedState(),
  activeId: null,
};

export const getAllWorkspaces = createAsyncThunk(
  "workspaces/getAll",
  async () => {
    const response = await fetchAll();
    return response.results;
  }
);

export const getWorkspace = createAsyncThunk(
  "workspaces/getOne",
  async (id: string) => {
    const response = await fetchOne(id);
    return response;
  }
);

export const createWorkspace = createAsyncThunk(
  "workspaces/create",
  async (body: any, { dispatch }) => {
    try {
      const response = await add(body);
      dispatch(setActiveWorkspace(response.id));
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const updateWorkspace = createAsyncThunk(
  "workspaces/update",
  async ({ id, ...body }: any, { dispatch }) => {
    try {
      const response = await update(id, body);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const setActiveWorkspace = createAction(
  "workspaces/setActive",
  (workspaceId: string) => {
    localStorage.setItem("activeWorkspaceId", workspaceId);
    return {
      payload: workspaceId,
    };
  }
);

export const getWorkspaceMembers = createAsyncThunk(
  "workspaces/getMembers",
  async (
    {
      workspaceId,
    }: {
      workspaceId?: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    workspaceId = workspaceId || state.workspaces.activeId || undefined;
    if (!workspaceId) throw new Error("No workspaceId provided");
    const members = await fetchMembers(workspaceId);
    return members;
  }
);

export const workspaceSlice = createSlice({
  name: "workspaces",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addManyItems: (state, action: PayloadAction<Array<any>>) => {
      addNormalizedItems(state, action.payload);
    },
    removeItem: (state, action: PayloadAction<string>) => {
      removeNormalizedItem(state, action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllWorkspaces.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, action.payload);
          if (!state.activeId && action.payload.length > 0) {
            state.activeId = action.payload[0].id;
          }
        }
      )
      .addCase(
        getWorkspace.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
          if (!state.activeId) {
            state.activeId = action.payload.id;
          }
        }
      )
      .addCase(
        setActiveWorkspace,
        (state: any, action: PayloadAction<string>) => {
          state.activeId = action.payload;
        }
      )
      .addCase(
        createWorkspace.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
          if (!state.activeId) {
            state.activeId = action.payload.id;
          }
        }
      )
      .addCase(createWorkspace.rejected, (state: any, action: any) => {
        throw action.error;
      })
      .addCase(loadAllProfileData.fulfilled, (state: any, action: any) => {
        if (!state.activeId) {
          state.activeId = action.payload.activeWorkspaceId;
        }
        addNormalizedItems(state, action.payload.workspaces);
      })
      .addCase(logout.fulfilled, (state: any, action: PayloadAction<void>) => {
        state.activeId = null;
        state = initialState;
      });
  },
});

export const selectAllWorkspaces = createSelector(
  (state: RootState) => toArray(state.workspaces),
  (a) => a
);

export const selectActiveWorkspace = (state: RootState) =>
  state.workspaces.activeId
    ? state.workspaces.byId[state.workspaces.activeId]
    : null;
export const selectActiveWorkspaceId = (state: RootState) =>
  state.workspaces.activeId;

export default workspaceSlice.reducer;

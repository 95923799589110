import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { logout } from "../features/auth/authSlice";
import { CenteredSpinner } from "../components/Spinner";

export default function () {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  async function handleLogout() {
    await dispatch(logout());
    navigate("/login");
  }

  useEffect(() => {
    handleLogout();
  }, []);
  return <CenteredSpinner />;
}

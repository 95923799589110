import { useState } from "react";
import { format, parseISO } from "date-fns";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import styles from "./Comment.module.css";
import {
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useAppDispatch } from "../../../app/hooks";
import { archiveComment } from "./commentSlice";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { ArrowUpCircleIcon as ArrowUpCircleIconSolid } from "@heroicons/react/24/solid";

export function Comment({ comment }: { comment: any }) {
  return (
    <div className={`py-3 px-4 ${styles["comment-card"]} border-t`}>
      <div className="flex flex-row">
        <div className="flex flex-row flex-1 items-end py-0.5">
          <div className="font-medium text-sm text-gray-800">
            {comment.author.name}
          </div>
          <div
            className="pl-4 text-gray-400"
            style={{
              fontSize: "0.6rem",
              lineHeight: "1rem",
              marginBottom: 0.5,
            }}
          >
            {format(parseISO(comment.createdAt), "H:mma MMM d")}
          </div>
        </div>
        <div>
          <CommentOptionsButton commentId={comment.id} />
        </div>
      </div>
      <div className="text-sm">{comment.content}</div>
    </div>
  );
}

export function AddCommentForm({
  onChange,
  value,
  onSubmit,
  autoFocus,
}: {
  onChange: (value: string) => void;
  value: string;
  onSubmit: () => void;
  autoFocus?: boolean;
}) {
  const [inputIsFocused, setInputIsFocused] = useState(false);
  function handleSubmit(e: any) {
    e.preventDefault();
    onSubmit();
  }
  return (
    <form onSubmit={handleSubmit} className="border-t flex flex-row relative">
      <input
        id="comment"
        name="comment"
        type="text"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder="Write your comment here"
        className="block w-full rounded-b-md border-0 py-2 pr-8 pl-4 text-gray-900 shadow-sm ring-none placeholder:text-gray-400 focus:ring-transparent sm:text-sm sm:leading-6"
        autoFocus={autoFocus}
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
      />
      <button className="text-gray-800 rounded py-2 px-3 text-sm absolute right-2">
        {inputIsFocused || value.length > 0 ? (
          <ArrowUpCircleIconSolid className="w-6 h-6 text-indigo-500" />
        ) : (
          <ArrowUpCircleIcon className="w-6 h-6 text-gray-400" />
        )}
      </button>
    </form>
  );
}

export function CommentOptionsButton({ commentId }: { commentId: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "right-start",
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const dispatch = useAppDispatch();
  function handleDeleteClick() {
    dispatch(archiveComment(commentId));
  }
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={() => setIsOpen(!isOpen)}
        className={`text-gray-400 hover:text-gray-800 ${styles["comment-card__options-button"]} hover:bg-gray-100 rounded-full p-1.5 -mt-1`}
      >
        <EllipsisHorizontalIcon className="w-4 h-4" />
      </button>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="bg-white border border-gray-300 rounded text-sm shadow-lg"
          {...getFloatingProps()}
        >
          {/* <button className="px-3 py-2 hover:bg-gray-100 text-gray-900 block w-full text-left">
            Edit
          </button> */}
          <button
            className="px-3 py-2 hover:bg-gray-100 text-gray-900 block w-full text-left"
            onClick={() => handleDeleteClick()}
          >
            Delete
          </button>
        </div>
      ) : null}
    </>
  );
}

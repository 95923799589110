import { ChartBarSquareIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import styles from "./Timeline.module.css";
import AnnotationColumn from "../annotation/AnnotationColumn";
import {
  useAnnotations,
  useAnnotationSelection,
} from "../annotation/annotationHooks";
import { useEffect, useRef, useState } from "react";
import { usePositionContext } from "../positionContext";
import { getAllAnnotations } from "../annotation/annotationSlice";
import { useAppDispatch } from "../../../app/hooks";

export default function Timeline({
  caseId,
  events,
  onAnalyticsOpenClick,
}: {
  caseId: string;
  events: any[];
  onAnalyticsOpenClick: (eventId: string) => void;
}) {
  const dispatch = useAppDispatch();
  const [selectedAnnotationId, setSelectedAnnotationId] = useState("");
  const eventBuckets = useEventBuckets(events);
  useEffect(() => {
    dispatch(getAllAnnotations({ caseId }));
  }, [caseId]);
  return (
    <div className="flex flex-row">
      <div
        className="pt-4"
        style={{ minWidth: 600, width: 600, maxWidth: 600 }}
      >
        {eventBuckets.map((bucket) => (
          <EventBucket
            key={bucket.date}
            events={bucket.events}
            onAnalyticsOpenClick={onAnalyticsOpenClick}
            onAnnotationClick={setSelectedAnnotationId}
          />
        ))}
        {events.length === 0 ? (
          <div className="italic">
            No events have been recorded for this case.
          </div>
        ) : null}
      </div>
      <div className="w-full max-w-lg pl-8">
        <AnnotationColumn
          caseId={caseId}
          eventIds={events.map((ev) => ev.id)}
          selectedAnnotationId={selectedAnnotationId}
          setSelectedAnnotationId={setSelectedAnnotationId}
        />
      </div>
    </div>
  );
}

function EventCard({
  event,
  onAnalyticsOpenClick,
  selectable = true,
  onAnnotationClick,
}: {
  event: any;
  onAnalyticsOpenClick?: () => void;
  selectable: boolean;
  onAnnotationClick: (annotationId: string) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const annotations = useAnnotations(event.caseId, [event.id]);

  return (
    <div
      className={`py-4 px-6 rounded-lg mb-4 bg-white shadow-sm border border-gray-100 min-w-3xl w-3xl max-w-3xl ${
        styles["event-card"]
      } ${selectable ? "" : "select-none"}`}
      ref={ref}
    >
      <div className="flex flex-row select-none">
        <div className="flex-1 flex flex-row items-center">
          <div className="text-xs text-gray-500">{event.name}</div>
          <div
            className="text-xs text-gray-500 ml-3"
            style={{ fontSize: "0.6rem" }}
          >
            {format(parseISO(event.createdAt), "h:mmaa")}
          </div>
        </div>
        <div className={`${styles["event-card-buttons"]}`}>
          {onAnalyticsOpenClick ? (
            <button
              className="text-gray-400 hover:text-gray-800"
              onClick={onAnalyticsOpenClick}
            >
              <ChartBarSquareIcon className="w-4 h-4" />
            </button>
          ) : null}
        </div>
      </div>
      <DetailsText
        eventId={event.id}
        content={event.details}
        annotations={annotations}
        onAnnotationClick={onAnnotationClick}
      />
    </div>
  );
}

function DetailsText({
  eventId,
  content,
  annotations,
  onAnnotationClick,
}: {
  eventId: string;
  content: string;
  annotations: any[];
  onAnnotationClick: (annotationId: string) => void;
}) {
  const { updatePosition } = usePositionContext();

  const { containerRef, parts, nodeRefs } = useAnnotationSelection(
    eventId,
    annotations,
    content
  );
  const partIndexByAnnotationId = parts.reduce((acc, part, i) => {
    if (!part.annotationId) return acc;
    if (acc[part.annotationId]) return acc;
    acc[part.annotationId] = i;
    return acc;
  }, {} as { [annotationId: string]: number });
  const nodeYs = nodeRefs.current
    ? Object.values(nodeRefs.current).map((el) => el?.getBoundingClientRect().y)
    : [];
  useEffect(() => {
    if (!nodeRefs.current) return;
    annotations.forEach((annotation, i) => {
      const el = nodeRefs.current[partIndexByAnnotationId[annotation.id]];
      if (!el) return;
      const rect = el.getBoundingClientRect();
      updatePosition(annotation.id, rect.y + window.scrollY);
    });
  }, [nodeYs, annotations]);
  function getClassNameFromStyleType(styleType: string | null) {
    if (styleType === "positive") {
      return "bg-green-100 hover:bg-green-300 cursor-pointer";
    } else if (styleType === "negative") {
      return "bg-red-100 hover:bg-red-300 cursor-pointer";
    } else if (styleType === "comment") {
      return "bg-amber-100 hover:bg-amber-300 cursor-pointer";
    } else if (styleType === null) {
      return "";
    } else {
      throw new Error("Unknown style type: " + styleType);
    }
  }
  const elements = parts.map((part, p) => (
    <span
      className={`${getClassNameFromStyleType(part.style)}`}
      key={p}
      ref={(el) => (nodeRefs.current[p] = el)}
      onClick={() => {
        if (part.annotationId) {
          onAnnotationClick(part.annotationId);
        }
      }}
    >
      {part.text}
    </span>
  ));
  return (
    <div className="mt-0.5 text-sm whitespace-pre-wrap" ref={containerRef}>
      {elements}
    </div>
  );
}

function EventBucket({
  events,
  onAnalyticsOpenClick,
  onAnnotationClick,
}: {
  events: any[];
  onAnalyticsOpenClick: (eventId: string) => void;
  onAnnotationClick: (annotationId: string) => void;
}) {
  return (
    <div className="mb-8">
      <div className="text-xs mb-1 pl-2 text-gray-500">
        {events.length
          ? format(parseISO(events[0].createdAt), "MMM d, yyyy")
          : null}
      </div>
      <div className="space-y-4">
        {events.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            onAnalyticsOpenClick={
              event.trace ? () => onAnalyticsOpenClick(event.id) : undefined
            }
            selectable={true}
            onAnnotationClick={onAnnotationClick}
          />
        ))}
      </div>
    </div>
  );
}

function useEventBuckets(events: any[]) {
  const eventBucketsByDate = events.reduce((acc, event) => {
    const date = format(parseISO(event.createdAt), "yyyy-MM-dd");
    acc[date] = acc[date] || [];
    acc[date].push(event);
    return acc;
  }, {});
  const eventBuckets = Object.entries(eventBucketsByDate)
    .map(([date, events]) => ({
      date,
      events,
    }))
    .sort((a, b) => a.date.localeCompare(b.date)) as {
    date: string;
    events: any[];
  }[];
  return eventBuckets;
}

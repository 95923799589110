import { createContext, useContext } from "react";

// Create a context
export const PositionContext = createContext<{
  positions: {
    [key: string]: number;
  };
  updatePosition: (key: string, position: number) => void;
}>({
  positions: {},
  updatePosition: (key: string, position: number) => {},
});

// Custom hook for consuming the context
export const usePositionContext = () => useContext(PositionContext);

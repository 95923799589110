import { Link } from "react-router-dom";

interface WorkspaceDropdownProps {
  workspaces: Array<any>;
  onChange: (workspaceId: string) => void;
  activeWorkspace: any | null;
}

function trimWorkspaceName(name: string) {
  const maxLength = 21;
  if (name.length <= maxLength) return name;
  return name.substring(0, maxLength - 3) + "...";
}

export default function WorkspaceDropdown({
  workspaces,
  onChange,
  activeWorkspace,
}: WorkspaceDropdownProps) {
  if (!activeWorkspace) return null;
  return (
    <div className="inline-block">
      <Link
        to="/workspaces"
        className="cursor-pointer inline-flex w-full justify-center gap-x-1.5 rounded-md pl-1 py-2 text-sm font-semibold text-gray-700 hover:bg-white"
      >
        <div className="w-6 h-6 bg-indigo-600 text-white rounded pt-0.5 text-center">
          {(activeWorkspace.name.slice(0, 1) || "").toUpperCase()}
        </div>
        <div
          className="pt-0.5 pl-1 whitespace-nowrap overflow-x-hidden"
          style={{ fontSize: 13, width: 140 }}
        >
          {trimWorkspaceName(activeWorkspace.name)}
        </div>
      </Link>
    </div>
  );
}

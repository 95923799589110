import axios from "axios";

export async function fetchAll(params: {
  workspaceId: string;
}): Promise<{ results: Array<any> }> {
  const { data } = await axios.get("/api/labels", { params });
  return data;
}
export async function create(body: any): Promise<any> {
  const { data } = await axios.post(`/api/labels`, body);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/labels/${id}`, body);
  return data;
}

export async function destroy(id: string): Promise<any> {
  const { data } = await axios.delete(`/api/labels/${id}`);
  return data;
}

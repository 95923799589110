import PaddedContainer from "../components/containers/PaddedContainer";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import {
  getProject,
  selectProjectById,
} from "../features/projects/projectSlice";
import Project from "../features/projects/Project";

export default function ProjectView() {
  const { projectId } = useParams();
  const project = useAppSelector((state) =>
    projectId ? selectProjectById(state, projectId) : null
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!projectId) return;
    dispatch(getProject(projectId));
  }, []);
  if (!project) return null;
  return (
    <div className="flex-1 flex flex-col max-h-screen">
      <Project project={project} />
    </div>
  );
}

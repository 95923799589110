import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import axios from "axios";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./app/router";
import * as Sentry from "@sentry/react";
import { BrowserClient } from "@sentry/browser";
import config from "./config";
import io from "socket.io-client";
import "./index.css";

axios.defaults.timeout = 10000;
if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "*.onrender.com",
          "rvillanueva.dev",
          "*.merolot.dev",
        ],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const ws = io();
ws.on("connect", () => {
  if (import.meta.hot) {
    import.meta.hot.on("beforeUpdate", () => {
      ws.close();
    });
    import.meta.hot.on("beforeFullReload", () => {
      ws.close();
    });
  }
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

async function lazyLoad() {
  const { Replay } = await import("@sentry/browser");
  const sentryClient = Sentry.getCurrentHub().getClient<BrowserClient>();

  // Client can be undefined
  sentryClient?.addIntegration(new Replay());
}

lazyLoad();

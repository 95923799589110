import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import auth from "../features/auth/authSlice";
import workspaces from "../features/workspaces/workspaceSlice";
import workspaceMembers from "../features/team/memberSlice";
import invites from "../features/team/inviteSlice";
import projects from "../features/projects/projectSlice";
import traces from "../features/tracing/traces/traceSlice";
import cases from "../features/tracing/cases/caseSlice";
import events from "../features/tracing/events/eventSlice";
import annotations from "../features/tracing/annotation/annotationSlice";
import comments from "../features/tracing/annotation/commentSlice";
import labels from "../features/tracing/labels/labelSlice";
export const storeConfig = {
  reducer: {
    auth,
    workspaces,
    workspaceMembers,
    invites,
    projects,
    traces,
    cases,
    events,
    annotations,
    comments,
    labels,
  },
};
export const store = configureStore(storeConfig);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

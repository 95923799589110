import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { archive, create } from "./commentAPI";
import {
  addNormalizedItems,
  initNormalizedState,
  removeNormalizedItem,
} from "../../../utils/normalizedState";
import { uuidv4 } from "../../../../../shared/utils/uuid";
import {
  addCommentIdToAnnotation,
  getAllAnnotations,
  submitAnnotation,
} from "./annotationSlice";

const initialState: {
  byId: any;
  allIds: Array<string>;
} = { ...initNormalizedState() };

export const addAndSubmitComment = createAsyncThunk(
  "annotations/addAndSubmitComment",
  async (
    body: { annotationId: string; content: string },
    { dispatch, getState }
  ) => {
    const id = uuidv4();
    const state = getState() as RootState;
    const author = state.auth.user;
    dispatch(
      addComment({
        id,
        annotationId: body.annotationId,
        content: body.content,
        author,
        createdAt: new Date().toISOString(),
      })
    );
    dispatch(
      addCommentIdToAnnotation({
        annotationId: body.annotationId,
        commentId: id,
      })
    );

    return create({ ...body, id });
  }
);

export const archiveComment = createAsyncThunk(
  "comments/archive",
  async (id: string) => {
    return archive(id);
  }
);

export const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    addComment: (
      state,
      action: PayloadAction<{
        id: string;
        content: string;
        author: {
          id: string;
          name: string;
          email: string;
        };
        annotationId: string;
        createdAt: string;
      }>
    ) => {
      addNormalizedItems(state, [action.payload]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        addAndSubmitComment.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        submitAnnotation.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, action.payload.comments);
        }
      )
      .addCase(
        getAllAnnotations.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(
            state,
            action.payload.results.flatMap(
              (annotation: any) => annotation.comments
            )
          );
        }
      )
      .addCase(
        archiveComment.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          removeNormalizedItem(state, action.payload.id);
        }
      );
  },
});

export const { addComment } = commentSlice.actions;

export default commentSlice.reducer;

import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  unwrapResult,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { fetchAll, create, fetchOne, archive } from "./annotationAPI";
import {
  addNormalizedItems,
  initNormalizedState,
  removeNormalizedItem,
} from "../../../utils/normalizedState";
import { uuidv4 } from "../../../../../shared/utils/uuid";
import { toArray } from "lodash";
import { addComment } from "./commentSlice";

interface AnnotationSelection {
  eventId: string;
  startIndex: number;
  endIndex: number;
}

const initialState: {
  selection: AnnotationSelection | null;
  byId: any;
  allIds: Array<string>;
} = { ...initNormalizedState(), selection: null };

export const getAllAnnotations = createAsyncThunk(
  "annotations/getAll",
  async (params: any) => {
    const { skip = 0, limit, ...filter } = params;
    const { results, totalCount } = await fetchAll({
      ...params,
    });
    return { results, totalCount, filter, skip };
  }
);

export const submitAnnotation = createAsyncThunk(
  "annotations/create",
  async (
    body: {
      annotationId: string;
      content: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const annotationDraft = state.annotations.byId[body.annotationId];

    if (!annotationDraft) {
      throw new Error("Annotation draft not found.");
    }
    dispatch(
      setAnnotationContent({ id: body.annotationId, content: body.content })
    );
    const { id: annotationId, ...annotationData } = annotationDraft;
    const annotation = await create({
      ...annotationData,
      annotationId: annotationDraft.id,
      content: body.content,
    });
    return annotation;
  }
);

export const createDraftAnnotation = createAsyncThunk(
  "annotations/createDraft",
  (body: { type: "positive" | "negative" | "comment" }, { getState }) => {
    const state = getState() as RootState;
    return {
      id: uuidv4(),
      type: body.type,
      draft: true,
      comments: [],
      author: state.auth.user,
    };
  }
);

export const archiveAnnotation = createAsyncThunk(
  "annotations/archive",
  async (id: string) => {
    return archive(id);
  }
);

export const annotationSlice = createSlice({
  name: "annotations",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSelection: (
      state,
      action: PayloadAction<AnnotationSelection | null>
    ) => {
      state.selection = action.payload;
    },
    createDraftAnnotation: (
      state,
      action: PayloadAction<AnnotationSelection | null>
    ) => {
      addNormalizedItems(state, [action.payload]);
    },
    removeDraftAnnotation: (state, action: PayloadAction<string>) => {
      const annotation = state.byId[action.payload];
      if (!annotation.draft) {
        throw new Error("Annotation is not a draft.");
      }
      delete state.byId[action.payload];
      state.allIds = state.allIds.filter((id) => id !== action.payload);
    },
    setAnnotationContent: (
      state,
      action: PayloadAction<{
        id: string;
        content: string;
        draft?: boolean;
      }>
    ) => {
      state.byId[action.payload.id].content = action.payload.content;
      if (action.payload.draft) {
        state.byId[action.payload.id].draft = true;
      } else {
        delete state.byId[action.payload.id].draft;
      }
    },
    addCommentIdToAnnotation: (
      state,
      action: PayloadAction<{
        annotationId: string;
        commentId: string;
      }>
    ) => {
      state.byId[action.payload.annotationId].commentIds.push(
        action.payload.commentId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllAnnotations.fulfilled,
        (
          state: any,
          action: PayloadAction<{
            results: Array<any>;
          }>
        ) => {
          addNormalizedItems(
            state,
            action.payload.results.map(flattenAnnotation)
          );
        }
      )
      .addCase(
        createDraftAnnotation.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (!state.selection) throw new Error("Selection not found.");
          addNormalizedItems(state, [
            {
              ...flattenAnnotation(action.payload),
              startIndex: state.selection.startIndex,
              endIndex: state.selection.endIndex,
              eventId: state.selection.eventId,
            },
          ]);
        }
      )
      .addCase(
        submitAnnotation.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [flattenAnnotation(action.payload)]);
          delete state.byId[action.payload.id].draft;
        }
      )
      .addCase(
        archiveAnnotation.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          removeNormalizedItem(state, action.payload.id);
        }
      );
  },
});
export const {
  setSelection,
  addCommentIdToAnnotation,
  removeDraftAnnotation,
  setAnnotationContent,
} = annotationSlice.actions;
export const selectSelection = (state: RootState) =>
  state.annotations.selection;

export const selectAllAnnotationsByEventIds = createSelector(
  (state: RootState) => state.annotations.byId,
  (state: RootState) => state.comments.byId,
  (state: RootState, eventIds: string[]) => eventIds,
  (annotations, commentsById, eventIds: string[]) => {
    return toArray(annotations)
      .filter((item: any) => eventIds.includes(item.eventId))
      .map((annotation: any) => joinEntities(annotation, commentsById));
  }
);

export default annotationSlice.reducer;

function flattenAnnotation(annotation: any) {
  const obj = {
    ...annotation,
    commentIds: annotation.comments.map((comment: any) => comment.id),
  };
  delete obj.comments;
  return obj;
}

function joinEntities(annotation: any, commentsById: any) {
  return {
    ...annotation,
    comments: annotation.commentIds
      .map((id: string) => commentsById[id])
      .filter((c: any) => c && !c.archivedAt),
  };
}

import { getInitials } from "../../../../shared/utils/string";

function getStyle(size: "sm" | "md") {
  switch (size) {
    case "sm":
      return {
        diameter: 24,
        fontSize: 11,
      };
    case "md":
      return {
        diameter: 32,
        fontSize: 13,
      };

    default:
      throw Error(`Unknown size ${size}.`);
  }
}

export default function Avatar({
  name,
  size = "md",
}: {
  name: string;
  size?: "sm" | "md";
}) {
  const initials = getInitials(name);
  const { diameter, fontSize } = getStyle(size);
  return (
    <div
      className="inline-block text-white bg-blue-500 rounded-full shadow-solid"
      style={{ width: diameter, height: diameter, fontSize }}
    >
      <div className="flex flex-col justify-center items-center h-full w-full">
        {initials || "-"}
      </div>
    </div>
  );
}

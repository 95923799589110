import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import PaddedContainer from "../components/containers/PaddedContainer";
import ProjectCreateForm from "../features/projects/ProjectCreateForm";
import { createProject } from "../features/projects/projectSlice";
import { throwIfError } from "../utils/error";

export default function ProjectCreateView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleSubmit(form: any) {
    const { payload: project } = await throwIfError(
      dispatch(createProject(form))
    );
    navigate(`/projects/${project.id}`);
  }
  return (
    <div className="pt-24 pb-24">
      <PaddedContainer>
        <ProjectCreateForm onSubmit={handleSubmit} />
      </PaddedContainer>
    </div>
  );
}

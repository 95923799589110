import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { updateWorkspace } from "./workspaceSlice";
import { handleError } from "../errors/errorUtils";
import SubmitButton from "../../components/form/SubmitButton";
import { throwOnRejected } from "../../utils/thunk";
import { toast } from "react-toastify";

export default function WorkspaceSettingsForm({
  workspace,
}: {
  workspace: any;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState(workspace.name);
  const isDirty = name === workspace.name;
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      await throwOnRejected(
        dispatch(updateWorkspace({ id: workspace.id, name }))
      );
      toast.success("Workspace updated.");
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  return (
    <form onSubmit={handleSubmit} className="mt-8 max-w-5xl">
      <div>
        <label
          htmlFor="workspace-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Workspace Name
        </label>
        <div className="mt-2">
          <input
            onChange={(e) => setName(e.target.value)}
            ref={inputRef}
            value={name}
            id="workspace-name"
            name="workspace-name"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="flex flex-row justify-end mt-2">
          <SubmitButton
            style={{ width: 120 }}
            disabled={isDirty}
            submitting={loading}
          >
            Save
          </SubmitButton>
        </div>
      </div>
    </form>
  );
}

import { useEffect } from "react";
import { Redirect } from "../features/nav/Redirect";
import Project from "../features/projects/Project";
import ProjectSettings from "../features/projects/ProjectSettings";
import {
  getProject,
  selectProjectById,
} from "../features/projects/projectSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useParams } from "react-router-dom";

export default function ProjectSettingsView() {
  const { projectId } = useParams();
  const project = useAppSelector((state) =>
    projectId ? selectProjectById(state, projectId) : null
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!projectId) return;
    dispatch(getProject(projectId));
  }, []);
  if (!project) return null;
  return (
    <div className="pb-12 px-24">
      <ProjectSettings project={project} />
    </div>
  );
}

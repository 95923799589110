import { FormEvent, useState } from "react";
import { requestMagicLink } from "./authAPI";
import { extractErrorMessage } from "../../utils/error";
import validator from "validator";
import { CenteredSpinner } from "../../components/Spinner";
interface AuthFormProps {
  title: string;
}

function MagicLinkEmailSentMessage({ email }: { email: string }) {
  return (
    <div>
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        Check your email
      </h2>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md text-center text-gray-600 space-y-1">
        <p>We sent a temporary login link.</p>
        <p>
          Please check your inbox at <span className="font-bold">{email}</span>.
        </p>
      </div>
    </div>
  );
}

interface LoginButtonsProps {
  title: string;
  onSuccess: (email: string) => void;
}

function LoginButtons({ title, onSuccess }: LoginButtonsProps) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const [showEmail, setShowEmail] = useState(false);
  const handleEmailFormSubmit = async (e: FormEvent) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      if (submitting) return;
      if (!showEmail) return setShowEmail(true);
      if (!email || !validator.isEmail(email))
        return setError("Please enter a valid email address.");
      setSubmitting(true);
      await requestMagicLink(email);
      onSuccess(email);
    } catch (err: any) {
      if (err.response?.status !== 400) {
        console.error(err);
      }
      setError(extractErrorMessage(err));
    } finally {
      setSubmitting(false);
    }
  };
  if (submitting) return <CenteredSpinner />;
  const emailInput = (
    <div className="pt-10 pb-2">
      <input
        id="email"
        name="email"
        type="text"
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email address..."
        className="block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      {error ? <div className="text-red-600 text-xs pt-1">{error}</div> : null}
    </div>
  );

  return (
    <>
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        {title}
      </h2>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8 px-4 sm:px-10 space-y-3">
          <a
            href="/auth/google"
            className="flex w-full justify-center rounded-md bg-indigo-600 py-3 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Continue with Google
          </a>
          <form onSubmit={handleEmailFormSubmit}>
            {showEmail ? emailInput : null}
            <button
              type="submit"
              name="sign-in__email"
              disabled={submitting}
              className="flex w-full justify-center rounded-md bg-white py-3 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Continue with Email
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default function AuthForm({ title }: AuthFormProps) {
  const [magicLinkSentTo, setSentMagicLinkTo] = useState<string | null>(null);
  const handleMagicLinkSuccess = (email: string) => {
    setSentMagicLinkTo(email);
  };
  return (
    <>
      <div className="flex-1 flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        {magicLinkSentTo ? (
          <MagicLinkEmailSentMessage email={magicLinkSentTo} />
        ) : (
          <LoginButtons onSuccess={handleMagicLinkSuccess} title={title} />
        )}
      </div>
    </>
  );
}

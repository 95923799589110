import TraceDialog from "../traces/TraceDialog";
import { useCallback, useState } from "react";
import Timeline from "../events/Timeline";
import { CaseStatusWithText } from "./CaseStatus";
import { PositionContext } from "../positionContext";
import CaseLabelControls from "../labels/CaseLabelControls";

export default function Case({
  caseItem,
  events,
}: {
  caseItem: any;
  events: any[];
}) {
  // State to store the positions
  const [positions, setPositions] = useState<{ [key: string]: number }>({});

  // Function to update a specific ref's position
  const updatePosition = useCallback((key: string, position: number) => {
    setPositions((prevPositions) => {
      if (prevPositions[key] === position) return prevPositions;
      return {
        ...prevPositions,
        [key]: position,
      };
    });
  }, []);
  const [selectedEventId, setSelectedEventId] = useState("");
  const selectedEvent = events.find((event) => event.id === selectedEventId);
  function handleAnalyticsOpenClick(eventId: string) {
    setSelectedEventId(eventId);
  }
  return (
    <PositionContext.Provider value={{ positions, updatePosition }}>
      <div className="w-5xl max-w-5xl min-w-5xl">
        <div className="flex flex-row">
          <h2 className="text-xl mb-6 flex-1">{caseItem.name || "Untitled"}</h2>
          <div className="mr-2 flex flex-row space-x-4 items-start">
            <CaseLabelControls
              caseId={caseItem.id}
              activeLabels={caseItem.labels || []}
            />
            <CaseStatusWithText status={caseItem.status} caseId={caseItem.id} />
          </div>
        </div>
        <Timeline
          events={events}
          onAnalyticsOpenClick={handleAnalyticsOpenClick}
          caseId={caseItem.id}
        />
        {selectedEvent?.trace ? (
          <TraceDialog
            open={!!selectedEvent?.trace}
            setOpen={() => setSelectedEventId("")}
            trace={selectedEvent?.trace}
          />
        ) : null}
      </div>
    </PositionContext.Provider>
  );
}

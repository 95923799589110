import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchOne as fetchInvite } from "../features/team/inviteAPI";
import { handleError } from "../features/errors/errorUtils";
import { CenteredSpinner } from "../components/Spinner";

type SurveyViewParams = {
  token: string;
};

export default function AcceptInviteView() {
  const { token = "" } = useParams<SurveyViewParams>();
  const [invite, setInvite] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getInvite();
  }, []);

  async function getInvite() {
    try {
      setInvite(await fetchInvite(token));
    } catch (err: any) {
      if (err?.response?.status === 404) return;
      handleError(err);
    } finally {
      setLoading(false);
    }
  }
  if (!invite) {
    if (!loading)
      return <div>This invitation may be expired or already used.</div>;
    return (
      <div>
        <CenteredSpinner />
      </div>
    );
  }
  return (
    <div className="pt-24">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-lg mb-6">
          {invite.workspace.name} has invited you to join their workspace on AI
          Training.
        </h2>
        <p>
          Plucky helps you build generative AI into your products and workflows.
        </p>
        <p>
          To accept this invitation, log in as{" "}
          <span className="font-bold">{invite.email}</span>.
        </p>
        <Link to="/login">
          <button>Log in</button>
        </Link>
      </div>
    </div>
  );
}

import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import {
  getTrace,
  selectTraceById,
} from "../features/tracing/traces/traceSlice";
import Trace from "../features/tracing/traces/Trace";

export default function TraceView() {
  const { traceId } = useParams();
  const trace = useAppSelector((state) =>
    traceId ? selectTraceById(state, traceId) : null
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!traceId) return;
    dispatch(getTrace(traceId));
  }, []);
  if (!trace) return null;
  return (
    <div className="py-24 px-24">
      <Trace trace={trace} />
    </div>
  );
}

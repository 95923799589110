import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { FormEvent, useEffect, useRef, useState } from "react";
import { createWorkspace } from "./workspaceSlice";
import { handleError } from "../errors/errorUtils";

export default function CreateWorkspaceForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      await dispatch(createWorkspace({ name }));
      navigate(`/`);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-row justify-center">
        <div style={{ width: 420 }}>
          <div className="text-center">
            <div className="text-lg font-semibold">Create a new workspace</div>
            <div className="text-gray-600 text-sm">
              Workspaces are shared environments where teams build software
              together.
            </div>
          </div>
          <form onSubmit={handleSubmit} className="mt-8">
            <div>
              <label
                htmlFor="workspace-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Workspace Name
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setName(e.target.value)}
                  ref={inputRef}
                  value={name}
                  id="workspace-name"
                  name="workspace-name"
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create workspace
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { getAllWorkspaces, setActiveWorkspace } from "./workspaceSlice";
import { acceptInvite } from "../team/inviteSlice";
interface WorkspaceListProps {
  workspaces: Array<any>;
  invites?: Array<any>;
}

interface WorkspaceListItemProps {
  name: string;
  workspaceId: string;
  onClick: () => void;
  buttonLabel: string;
}

function WorkspaceListItem({
  workspaceId,
  name,
  onClick,
  buttonLabel,
}: WorkspaceListItemProps) {
  return (
    <tr className="workspace-list-item" data-workspace-id={workspaceId}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {name || "Untitled"}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 flex flex-row justify-end">
        <button
          className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onClick}
        >
          {buttonLabel}
        </button>
      </td>
    </tr>
  );
}

export default function WorkspaceList({
  workspaces,
  invites,
}: WorkspaceListProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  async function handleNewSurveyClick() {
    navigate(`/workspaces`);
  }
  async function handleWorkspaceClick(workspaceId: string) {
    dispatch(setActiveWorkspace(workspaceId));
    navigate(`/`);
  }
  async function handleInviteClick(invite: any) {
    await dispatch(acceptInvite(invite.id));
    await dispatch(getAllWorkspaces());
    dispatch(setActiveWorkspace(invite.workspace.id));
    navigate(`/`);
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Workspaces
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Link
            to="/workspaces/create"
            className="block rounded-md bg-slate-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 hover:text-white focus-visible:outline-offset-2"
            onClick={handleNewSurveyClick}
          >
            Create Workspace
          </Link>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {workspaces.map((workspace) => (
                  <WorkspaceListItem
                    key={workspace.id}
                    workspaceId={workspace.id}
                    onClick={() => handleWorkspaceClick(workspace.id)}
                    name={workspace.name}
                    buttonLabel="Enter"
                  />
                ))}
                {(invites || []).map((invite) => (
                  <WorkspaceListItem
                    key={invite.id}
                    workspaceId={invite.workspace.id}
                    onClick={() => handleInviteClick(invite)}
                    name={invite.workspace.name}
                    buttonLabel="Join"
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {workspaces.length + (invites || []).length === 0 ? (
          <div className="text-center text-gray-500 text-sm">
            No workspaces available.{" "}
            <Link to="/workspaces/create">Create workspace.</Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

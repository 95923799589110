import { useSelector } from "react-redux";
import {
  getAllWorkspaces,
  selectAllWorkspaces,
} from "../features/workspaces/workspaceSlice";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import WorkspaceList from "../features/workspaces/WorkspaceList";
import {
  getAllReceivedInvites,
  selectAllReceivedInvites,
} from "../features/team/inviteSlice";
import PaddedContainer from "../components/containers/PaddedContainer";

export default function WorkspaceListView() {
  const workspaces = useSelector(selectAllWorkspaces);
  const invites = useSelector(selectAllReceivedInvites);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllWorkspaces());
    dispatch(getAllReceivedInvites());
  }, []);
  return (
    <div className="pt-24">
      <PaddedContainer>
        <WorkspaceList workspaces={workspaces} invites={invites} />
      </PaddedContainer>
    </div>
  );
}

import isEmail from "validator/lib/isEmail";

export function getInitials(name: string) {
  const parts = name.split(" ");
  if (parts.length === 0) return "";
  if (parts.length === 1) return parts[0].substring(0, 1).toUpperCase();
  const initials =
    parts[0].substring(0, 1) + parts[parts.length - 1].substring(0, 1);
  return initials.toUpperCase();
}

export function joinNames(firstName: string, lastName: string) {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  if (!firstName && !lastName) return "";
}

export function getEmailLocalPart(email: string) {
  return email.split("@")[0];
}

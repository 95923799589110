import { CSSProperties, MouseEvent, ReactNode } from "react";
import Spinner from "../Spinner";

interface SubmitButtonProps {
  onClick?: (e: MouseEvent) => void;
  children?: ReactNode;
  submitting?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  type?: "button" | "submit";
  className?: string;
}

export default function SubmitButton({
  onClick,
  children,
  submitting,
  disabled,
  style,
  className,
  type = "submit",
}: SubmitButtonProps) {
  const color =
    disabled || submitting
      ? "bg-indigo-300"
      : "bg-indigo-600 hover:bg-indigo-500";
  return (
    <button
      style={style}
      type={type}
      className={
        className ||
        `inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${color}`
      }
      onClick={onClick}
      disabled={disabled || submitting}
    >
      {submitting ? <Spinner size="sm" color="light" /> : children}
    </button>
  );
}

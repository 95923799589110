import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  addNormalizedItems,
  initNormalizedState,
  removeNormalizedItem,
} from "../../../utils/normalizedState";
import { getAllCases } from "../cases/caseSlice";
import { create, destroy, fetchAll, update } from "./labelAPI";
import { RootState } from "../../../app/store";
import { toArray } from "lodash";

const initialState: {
  byId: any;
  allIds: Array<string>;
} = initNormalizedState();

export const getAllLabels = createAsyncThunk(
  "labels/getAll",
  async (_, { getState }) => {
    const { activeId: workspaceId } = (getState() as any).workspaces;
    const { results } = await fetchAll({
      workspaceId,
    });
    return { results };
  }
);

export const createLabel = createAsyncThunk(
  "labels/create",
  async ({ id, ...body }: any, { getState }) => {
    const { activeId: workspaceId } = (getState() as any).workspaces;
    const response = await create({ ...body, workspaceId });
    return response;
  }
);

export const updateLabel = createAsyncThunk(
  "labels/update",
  async ({ id, ...body }: any) => {
    const response = await update(id, body);
    return response;
  }
);

export const deleteLabel = createAsyncThunk(
  "labels/delete",
  async (id: string) => {
    const response = await destroy(id);
    return id;
  }
);
export const labelSlice = createSlice({
  name: "labels",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCases.fulfilled,
        (
          state: any,
          action: PayloadAction<{
            results: Array<any>;
            totalCount: number;
            filter: any;
            skip: number;
          }>
        ) => {
          const labels = action.payload.results
            .flatMap((caseItem) => caseItem.caseLabels)
            .map(
              (caseLabel: {
                label: {
                  id: string;
                  name: string;
                  color: string;
                };
                [key: string]: any;
              }) => ({
                id: caseLabel.label.id,
                name: caseLabel.label.name,
                color: caseLabel.label.color,
              })
            );
          addNormalizedItems(state, labels);
        }
      )
      .addCase(getAllLabels.fulfilled, (state, action) => {
        addNormalizedItems(state, action.payload.results);
      })
      .addCase(createLabel.fulfilled, (state, action) => {
        addNormalizedItems(state, [action.payload]);
      })
      .addCase(updateLabel.fulfilled, (state, action) => {
        addNormalizedItems(state, [action.payload]);
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        removeNormalizedItem(state, action.payload);
      });
  },
});

export const selectAllLabelsInWorkspace = createSelector(
  (state: RootState) => state.labels.byId,
  (state: RootState) => state.workspaces.activeId,
  (labelsById: any, workspaceId: string | null) => {
    return toArray(labelsById).filter(
      (item) => item.workspaceId === workspaceId
    );
  }
);

export default labelSlice.reducer;

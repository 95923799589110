import axios from "axios";

export async function fetchAll(): Promise<{ results: Array<any> }> {
  const { data } = await axios.get("/api/workspaces");
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/workspaces/${id}`);
  return data;
}

export async function add(body: any): Promise<any> {
  const { data } = await axios.post(`/api/workspaces`, body);
  return data;
}

export async function update(surveyId: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/workspaces/${surveyId}`, body);
  return data;
}

export async function fetchMembers(workspaceId: string): Promise<any> {
  const { data } = await axios.get(`/api/workspaces/${workspaceId}/members`);
  return data;
}

export async function createInvite(
  workspaceId: string,
  email: string
): Promise<any> {
  const { data } = await axios.post(`/api/workspaces/${workspaceId}/invites`, {
    email,
  });
  return data;
}

export async function fetchAllInvites(workspaceId: string): Promise<any> {
  const { data } = await axios.get(`/api/workspaces/${workspaceId}/invites`);
  return data;
}

const useHttps = !!import.meta.env.VITE_USE_HTTPS;
const websocketHost = import.meta.env.VITE_DOMAIN || "localhost:3000";
const hocuspocusHost = import.meta.env.VITE_HOCUSPOCUS_HOST || "localhost:5000";

const config = {
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN,
  },
  useHttps,
  hocuspocus: {
    url: `${useHttps ? "wss" : "ws"}://${hocuspocusHost}`,
  },
  websocket: {
    url: `${useHttps ? "wss" : "ws"}://${websocketHost}`,
  },
};

export default config;

import axios from "axios";

export async function fetchAll(params: {
  caseId: string;
  skip: number;
  limit: number;
}): Promise<{ results: Array<any>; totalCount: number }> {
  const { data } = await axios.get("/api/annotations", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/annotations/${id}`);
  return data;
}
export async function create(body: {
  id: string;
  type: "positive" | "negative" | "comment";
  content: string;
  commentId: string;
  annotationId: string;
}): Promise<any> {
  const { data } = await axios.post(`/api/annotations`, body);
  return data;
}
export async function archive(id: string): Promise<any> {
  const { data } = await axios.post(`/api/annotations/${id}/archive`);
  return data;
}

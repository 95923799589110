import axios from "axios";

export async function fetchAll(params: {
  projectId: string;
}): Promise<{ results: Array<any>; totalCount: number }> {
  const { data } = await axios.get("/api/traces", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/traces/${id}`);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/traces/${id}`, body);
  return data;
}

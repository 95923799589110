import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMyProfile, submitLogin, submitLogout } from "./authAPI";
import { AxiosError } from "axios";
import { fetchAll as fetchAllWorkspaces } from "../workspaces/workspaceAPI";
import { patchMyProfile } from "../users/usersAPI";

const initialState = {
  user: null as null | any,
  isAuthPending: true as boolean,
};

interface LoginParams {
  email: string;
  password: string;
}

export const ERROR_CODE_UNAUTHORIZED = "UNAUTHORIZED";
export const ERROR_CODE_BAD_CREDENTIALS = "BAD_CREDENTIALS";

export class AuthError extends Error {
  code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

export const loadAllProfileData = createAsyncThunk(
  "auth/loadAllProfileData",
  async (_: any, { dispatch }) => {
    try {
      const user = await fetchMyProfile();
      const { results: workspaces } = await fetchAllWorkspaces();
      return {
        user,
        activeWorkspaceId:
          localStorage.getItem("activeWorkspaceId") || workspaces[0]?.id,
        workspaces,
      };
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 401:
            dispatch(logout());
            throw err;
        }
      }
      throw err;
    }
  }
);

export const getMyUserProfile = createAsyncThunk(
  "auth/getMyUserProfile",
  async (_: any, { dispatch }) => {
    try {
      return await fetchMyProfile();
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 401:
            dispatch(logout());
            return;
        }
      }
      throw err;
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }: LoginParams, { dispatch }) => {
    try {
      await submitLogin(email, password);
      const user = await dispatch(loadAllProfileData(null));
      return user;
    } catch (err) {
      if (err instanceof AxiosError) {
        switch (err.response?.status) {
          case 401:
            throw new AuthError(
              ERROR_CODE_BAD_CREDENTIALS,
              "Invalid email and password combination."
            );
        }
      }
      throw err;
    }
  }
);

export const updateMyProfile = createAsyncThunk(
  "users/updateMyProfile",
  async ({ name }: { name: string }, { dispatch }) => {
    const user = await patchMyProfile({ name });
    return user;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await submitLogout();
  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state: any, action: PayloadAction<void>) => {
        state.user = null;
        state.isAuthPending = true;
      })
      .addCase(login.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.user = action.payload;
      })
      .addCase(login.rejected, (state: any, action: any) => {
        state.isAuthPending = false;
        throw new AuthError(action.error.code, action.error.message);
      })
      .addCase(
        getMyUserProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.user = action.payload.user;
        }
      )
      .addCase(getMyUserProfile.rejected, (state: any, action: any) => {
        if (action.error?.code === ERROR_CODE_UNAUTHORIZED) {
          state.user = null;
        }
      })
      .addCase(logout.fulfilled, (state: any, action: PayloadAction<void>) => {
        state.user = null;
        state.isAuthPending = false;
      })
      .addCase(
        loadAllProfileData.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.user = action.payload.user;
          state.isAuthPending = false;
        }
      )
      .addCase(
        loadAllProfileData.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.isAuthPending = false;
        }
      )
      .addCase(
        updateMyProfile.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.user = action.payload;
        }
      );
  },
});

export default authSlice.reducer;

import axios from "axios";

export async function fetchOne(token: string): Promise<any> {
  const { data } = await axios.get(`/api/invites/${token}`);
  return data;
}

export async function create(workspaceId: string, email: string): Promise<any> {
  const { data } = await axios.post(`/api/invites`, {
    email,
    workspaceId,
  });
  return data;
}

interface FetchAllParams {
  workspaceId?: string;
  received?: boolean;
}

export async function fetchAll(params: FetchAllParams): Promise<any> {
  const { data } = await axios.get(`/api/invites`, {
    params,
  });
  return data;
}
export async function accept(inviteId: string): Promise<any> {
  const { data } = await axios.post(`/api/invites/${inviteId}/accept`);
  return data;
}

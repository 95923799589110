import CreateWorkspaceForm from "../features/workspaces/CreateWorkspaceForm";

export default function CreateWorkspaceView() {
  return (
    <div className="pt-6 flex-1 flex flex-col">
      <div className="flex-1 flex flex-col justify-center">
        <CreateWorkspaceForm />
      </div>
      <div className="flex-1" />
    </div>
  );
}

import axios from "axios";

export async function create(body: {
  id: string;
  content: string;
  annotationId: string;
}): Promise<any> {
  const { data } = await axios.post(`/api/comments`, body);
  return data;
}
export async function archive(id: string): Promise<any> {
  const { data } = await axios.post(`/api/comments/${id}`);
  return data;
}

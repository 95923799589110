import { useEffect, useState } from "react";
import {
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateCase } from "../cases/caseSlice";
import { getAllLabels, selectAllLabelsInWorkspace } from "./labelSlice";
import { CheckIcon } from "@heroicons/react/24/solid";
import CaseLabel from "./CaseLabel";

export default function CaseLabelControls({
  caseId,
  activeLabels,
}: {
  caseId: string;
  activeLabels: any[];
}) {
  const labels = useAppSelector(selectAllLabelsInWorkspace);
  useEffect(() => {
    dispatch(getAllLabels());
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating<HTMLButtonElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "left-start",
    middleware: [flip(), shift()],
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const dispatch = useAppDispatch();
  function handleToggleClick(toggledLabelId: string) {
    const labelIds = activeLabels.map((label) => label.id);
    const exists = labelIds.includes(toggledLabelId);
    if (exists) {
      dispatch(
        updateCase({
          id: caseId,
          labelIds: labelIds.filter((id) => id !== toggledLabelId),
        })
      );
    } else {
      dispatch(
        updateCase({
          id: caseId,
          labelIds: labelIds.concat(toggledLabelId),
        })
      );
    }

    setIsOpen(false);
  }

  return (
    <>
      <div className="flex flex-row space-x-2 items-center">
        {activeLabels.map((label) => (
          <CaseLabel key={label.id} label={label} />
        ))}
        <button
          ref={refs.setReference}
          onClick={() => setIsOpen(!isOpen)}
          className="text-sm py-1 px-3 hover:bg-gray-100 rounded font-medium text-gray-500"
          {...getReferenceProps()}
        >
          + Add Label
        </button>
      </div>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          className="bg-white shadow rounded text-sm p-1 divide-y-1 z-20"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {labels.map((label) => (
            <button
              key={label.id}
              className="w-full text-left px-4 py-2 rounded hover:bg-gray-100 flex flex-row"
              onClick={() => handleToggleClick(label.id)}
            >
              <div className="flex-1">{label.name}</div>
              {activeLabels.some(
                (activeLabel) => activeLabel.id === label.id
              ) ? (
                <div className="w-8 flex flex-row justify-end">
                  <CheckIcon className="w-4 h-4 text-gray-600" />
                </div>
              ) : null}
            </button>
          ))}
        </div>
      ) : null}
    </>
  );
}

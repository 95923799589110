import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  removeNormalizedItem,
} from "../../../utils/normalizedState";
import { fetchAll, fetchOne } from "./eventAPI";

interface ViewData {
  id: string;
  itemsByIndex: {
    [index: number]: any;
  };
  totalCount: number;
}

const initialState: {
  viewsById: {
    [id: string]: ViewData;
  };
  byId: any;
  allIds: Array<string>;
} = { ...initNormalizedState(), viewsById: {} };

export const getAllEvents = createAsyncThunk(
  "events/getAll",
  async (params: any) => {
    const { skip = 0, limit, ...filter } = params;
    const { results, totalCount } = await fetchAll({
      ...params,
    });
    return { results, totalCount, filter, skip };
  }
);

export const getEvent = createAsyncThunk(
  "events/getOne",
  async (id: string) => {
    const response = await fetchOne(id);
    return response;
  }
);

function serializeFilter(filter: { [key: string]: any }) {
  const obj: any = {};
  const keys = Object.keys(filter).sort();
  for (const key of keys) {
    obj[key] = filter[key];
  }
  return JSON.stringify(obj);
}

export const eventSlice = createSlice({
  name: "events",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addManyItems: (state, action: PayloadAction<Array<any>>) => {
      addNormalizedItems(state, action.payload);
    },
    removeItem: (state, action: PayloadAction<string>) => {
      removeNormalizedItem(state, action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllEvents.fulfilled,
        (
          state: any,
          action: PayloadAction<{
            results: Array<any>;
            totalCount: number;
            filter: any;
            skip: number;
          }>
        ) => {
          addNormalizedItems(state, action.payload.results);
          const { results, totalCount, filter, skip } = action.payload;
          const viewId = serializeFilter(filter);
          state.viewsById[viewId] = state.viewsById[viewId] || {
            id: viewId,
            itemsByIndex: {},
            totalCount,
          };
          state.viewsById[viewId].totalCount = totalCount;
          state.viewsById[viewId].itemsByIndex = {
            ...state.viewsById[viewId].itemsByIndex,
            ...results.reduce((acc: any, item: any, index: number) => {
              acc[index + skip] = item.id;
              return acc;
            }, {}),
          };
        }
      )
      .addCase(getEvent.fulfilled, (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(state, [action.payload]);
      });
  },
});

export const selectEventById = (state: RootState, id: string) =>
  state.events.byId[id];

export const selectEventViewByFilter = createSelector(
  (state: RootState) => state.events.viewsById,
  (state: RootState) => state.events.byId,
  (state: RootState, filter: any) => serializeFilter(filter),
  (viewsById, tracesById, viewId) => {
    if (!viewsById[viewId]) return null;
    return {
      ...viewsById[viewId],
      itemsByIndex: Object.entries(viewsById[viewId].itemsByIndex)
        .map(([index, id]) => ({
          [index]: tracesById[id],
        }))
        .reduce((acc: any, item: any) => {
          return { ...acc, ...item };
        }, {}),
    };
  }
);

export default eventSlice.reducer;

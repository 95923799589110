import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
} from "../../../utils/normalizedState";
import { fetchAll, fetchOne, update } from "./caseAPI";

interface ViewData {
  id: string;
  itemsByIndex: {
    [index: number]: any;
  };
  totalCount: number;
}

const initialState: {
  viewsById: {
    [id: string]: ViewData;
  };
  byId: any;
  allIds: Array<string>;
} = { ...initNormalizedState(), viewsById: {} };

export const getAllCases = createAsyncThunk(
  "cases/getAll",
  async (params: any) => {
    const { skip = 0, limit, ...filter } = params;
    const { results, totalCount } = await fetchAll({
      ...params,
    });
    return { results, totalCount, filter, skip };
  }
);

export const getCase = createAsyncThunk("cases/getOne", async (id: string) => {
  const response = await fetchOne(id);
  return response;
});

export const updateCase = createAsyncThunk(
  "cases/update",
  async (
    {
      id,
      status,
      labelIds,
    }: { id: string; status?: string; labelIds?: string[] },
    { dispatch }
  ) => {
    if (status) {
      dispatch(caseSlice.actions.setCaseStatus({ id, status }));
    }
    if (typeof labelIds !== "undefined") {
      dispatch(caseSlice.actions.setCaseLabelIds({ id, labelIds }));
    }
    const response = await update(id, { status, labelIds });
    return response;
  }
);

function serializeFilter(filter: { [key: string]: any }) {
  const obj: any = {};
  const keys = Object.keys(filter).sort();
  for (const key of keys) {
    obj[key] = filter[key];
  }
  return JSON.stringify(obj);
}

export const caseSlice = createSlice({
  name: "cases",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCaseStatus: (
      state,
      action: PayloadAction<{ id: string; status: string }>
    ) => {
      state.byId[action.payload.id].status = action.payload.status;
    },
    setCaseLabelIds: (
      state,
      action: PayloadAction<{ id: string; labelIds: string[] }>
    ) => {
      state.byId[action.payload.id].labelIds = action.payload.labelIds;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCases.fulfilled,
        (
          state: any,
          action: PayloadAction<{
            results: Array<any>;
            totalCount: number;
            filter: any;
            skip: number;
          }>
        ) => {
          addNormalizedItems(state, action.payload.results.map(flattenCase));
          const { results, totalCount, filter, skip } = action.payload;
          const viewId = serializeFilter(filter);
          state.viewsById[viewId] = state.viewsById[viewId] || {
            id: viewId,
            tracesByIndex: {},
            totalCount,
          };
          state.viewsById[viewId].totalCount = totalCount;
          state.viewsById[viewId].itemsByIndex = {
            ...state.viewsById[viewId].itemsByIndex,
            ...results.reduce((acc: any, item: any, index: number) => {
              acc[index + skip] = item.id;
              return acc;
            }, {}),
          };
        }
      )
      .addCase(getCase.fulfilled, (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(state, [flattenCase(action.payload)]);
      })
      .addCase(
        updateCase.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [flattenCase(action.payload)]);
        }
      );
  },
});

export const selectCaseById = createSelector(
  [
    (state: RootState) => state.cases.byId,
    (state: RootState) => state.labels.byId,
    (_: RootState, id: string) => id,
  ],
  (casesById, labelsById, caseId) =>
    casesById[caseId] ? joinCaseData(casesById[caseId], labelsById) : null
);

export const selectCaseViewByFilter = createSelector(
  [
    (state: RootState) => state.cases.viewsById,
    (state: RootState) => state.cases.byId,
    (state: RootState) => state.labels.byId,
    (state: RootState, filter: any) => serializeFilter(filter),
  ],
  (viewsById, casesById, labelsById, viewId) => {
    if (!viewsById[viewId]) return null;
    return {
      ...viewsById[viewId],
      itemsByIndex: Object.entries(viewsById[viewId].itemsByIndex)
        .map(([index, id]) => ({
          [index]: joinCaseData(casesById[id], labelsById),
        }))
        .reduce((acc: any, item: any) => {
          return { ...acc, ...item };
        }, {}),
    };
  }
);

export default caseSlice.reducer;

export function flattenCase({ caseLabels, ...rest }: any) {
  return {
    ...rest,
    labelIds: caseLabels.map((caseLabel: any) => caseLabel.label.id),
  };
}

function joinCaseData({ labelIds, ...caseItem }: any, labelsById: any) {
  return {
    ...caseItem,
    labels: labelIds.map((id: string) => labelsById[id]).filter((a: any) => a),
  };
}

import { FormEvent, useState } from "react";
import SubmitButton from "../../components/form/SubmitButton";
import { handleError } from "../errors/errorUtils";
import { updateMyProfile } from "../auth/authSlice";
import { useAppDispatch } from "../../app/hooks";
import { throwIfError } from "../../utils/error";

export default function ProfileSettings({ user }: { user: any }) {
  const dispatch = useAppDispatch();
  const [name, setNameValue] = useState(user.name);
  const [dirty, setDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  function setName(value: string) {
    setNameValue(value);
    setDirty(true);
  }

  async function handleSubmit(e: FormEvent) {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (!dirty) return;
      setSubmitting(true);
      await throwIfError(dispatch(updateMyProfile({ name })));
      setDirty(false);
    } catch (err) {
      console.error(err);
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Profile
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  type="name"
                  name="name"
                  id="name"
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter your full name here..."
                  aria-describedby="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">{user.email}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <SubmitButton submitting={submitting} disabled={!dirty}>
          Save
        </SubmitButton>
      </div>
    </form>
  );
}
